// PlotCounter.js
import React from 'react';
import './PlotCounter.css';

const PlotCounter = ({ count, prev_count, abs_diff, rel_diff }) => {
  // const labels = prev_count ? ['Count', 'Prev Count', 'Difference', 'Percentage'] : ['Count'];
  // const values = prev_count ? [count, prev_count, abs_diff, rel_diff+ '%'] : [count];
  const labels = ['Ofertas', 'Ofertas previas', 'Diferencia', 'Incremento'];
  const values = [count, prev_count, abs_diff, rel_diff+ '%'];
  return (
    <div className="plot-counter">
      <table>
        <thead>
          <tr className="tr-counter-stats">
            {labels.map((label, index) => (
              <th key={index} className="counter-label">{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="tr-counter-stats">
            <td className="counter-value" key={0}>{values[0]}</td>
            <td className="counter-value" key={1}>{values[1]}</td>
            <td className={values[2] < 0 ? 'counter-value negative' : values[2] > 0 ? 'counter-value positive' : 'counter-value zero'} key={2}>{values[2]}</td>
            <td className={Number(values[3].replace('%','')) < 0 ? 'counter-value negative' : Number(values[3].replace('%','')) > 0 ? 'counter-value positive' : 'counter-value zero'} key={3}>{values[3]}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PlotCounter;
