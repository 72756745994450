import React, { useState, useEffect } from 'react';
import { useReportContext } from '../../context/reportContext';

const ResultAddToReport = (props) => {
  const { id } = props;
  // Carga el contexto del report donde se almacenan los 'ids' de las opciones seleccionadas
  const { report, dispatchReport } = useReportContext();
  const [selectedIds, setSelectedIds] = useState(report['ids'])

  // Lógica para manejar el cambio en las opciones seleccionadas
  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      // Si la opción está seleccionada, la eliminamos
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    } else {
      // Si la opción no está seleccionada, la agregamos
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    }
  };

  // Lógica para manejar el cambio en las opciones seleccionadas
  const handleStateChange = () => {
    // Comprueba si selectedIds es diferente al estado actual
    if (JSON.stringify(selectedIds) !== JSON.stringify(report['ids'])) {
      // Solo realiza el dispatch si los valores son diferentes
      dispatchReport({ type: 'add', param: 'ids', selectedIds });
    }
  };
  
  useEffect(() => {
    // Llamamos a handleStateChange cuando selectedIds cambia
    handleStateChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);
  
  // Actualiza selectedIds cuando report.ids cambie a causa del ResultsDetail
  useEffect(() => {
    setSelectedIds(report.ids || []);
  }, [report.ids]);
  
  return (
      <div className="checkbox-list">
            <input
              type="checkbox"
              value={id}
              checked={selectedIds.includes(id)}
              onChange={() => handleCheckboxChange(id)}
            />
      </div>
  );
};

export default ResultAddToReport;
