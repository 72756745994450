import React, { useState, useEffect } from 'react';
import './DetailAddToReport.css'; // Asegúrate de tener un archivo CSS adecuado
import { useReportContext } from '../../context/reportContext';

const DetailAddToReport = (props) => {
  const { id } = props;
  // Carga el contexto del report donde se almacenan los 'ids' de las opciones seleccionadas
  const { report, dispatchReport } = useReportContext();
  const [selectedIds, setSelectedIds] = useState(report['ids'])

  // Lógica para manejar el cambio en las opciones seleccionadas
  const handleButtonChange = (id) => {
    if (selectedIds.includes(id)) {
      // Si la opción está seleccionada, la eliminamos
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    } else {
      // Si la opción no está seleccionada, la agregamos
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    }
  };

  // Lógica para manejar el cambio en el estado
  const handleStateChange = () => {
    dispatchReport({type:'add', param:"ids", selectedIds})
  };

  // Llamamos a handleStateChange cada vez que selectedIds cambia
  useEffect(() => {
    handleStateChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  return (
    <button
        className={`button ${selectedIds.includes(id) ? 'selected' : 'notSelected'}`}
        onClick={() => handleButtonChange(id)}
    >
    {selectedIds.includes(id) ? 'Quitar del informe' : 'Añadir a informe'}
    </button>
  );
};

export default DetailAddToReport;
