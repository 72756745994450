import React, { createContext, useContext, useEffect } from 'react';
import useUser from '../services/useUser';

// Creamos el contexto
  const UserContext = createContext(null);

//console.log(user);

// Proveedor del contexto de la búsqueda
export function UserProvider(props) {

  const { user: initialUser } = useUser();

  useEffect(()=> localStorage.setItem("user", JSON.stringify(initialUser)),[initialUser]);
  const contextValue = {
    initialUser,
  };


  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useUserContext() {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error(
      "useUserContext must be used within a ReportProvider. Wrap a parent component in <ReportProvider> to fix this error."
    )
  }
  return context;
}