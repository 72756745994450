import React from 'react';
import "./Footer.css"; 
import { FaCopyright, FaFacebook, FaSquareXTwitter, FaLinkedin } from 'react-icons/fa6';
import useSearch from '../../services/useSearch';

const Footer = () => {

  const { results } = useSearch(`release/`);
  return (
    <footer className='footer'>
      <div className='container footer-content flex'>
        <div className='footer-left'>
          <p className='footer-p'> {results} Desarrollado por <a href="https://www.databot.es">Databot Technologies</a></p>
          <div className='copyright'>
            <span className='icon_copyright'><FaCopyright /></span>
            <p className='footer-p copy'> Servicio Navarro de empleo - Nafar Lansare</p>
          </div>

        </div>
        <div className='footer-right'>
          <div className='footer-links'>
            <ul className='footer-nav'>
            <li className='footer-item'>
                <a href='https://www.databot.es/wp-content/uploads/2024/06/Navarra_Empleo_DGTD-Manual_de_usuario.pdf' target="_blank" className='footer-link'>Manual de usuario</a>
              </li>
              <li className='footer-item margins'>
                <a href='mailto:help@databot.es?subject=Incidencia%20-%20PortalEmpleoDGDT' className='footer-link'>Informar de incidencia</a>
              </li>
              <li className='footer-item margins'>
                <a href='/about' className='footer-link'>Aviso Legal</a>
              </li>
              <li className='footer-item margins'>
                <a href='/contact' className='footer-link'>Política de Protección de datos</a>
              </li>
              <li className='footer-item'>
                <a href='/privacy' className='footer-link'>Política de Cookies</a>
              </li>
              {/* Add more footer links as needed */}
            </ul>
          </div>

        </div>

      </div>
    </footer>
  )
}

export default Footer
