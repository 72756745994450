import React from 'react';
import "./OptionsLoader.css";

const OptionsLoader = (props) => {
    const { param } = props;

    return (
    // <div className='loader flex flex-c'>
    //   <img src = {LoaderImg} alt = "loader" />
    // </div>
    <div><p>Cargando {param}....</p></div>
  )
}

export default OptionsLoader