import React, { useState } from 'react';
import axios from 'axios';
import "./LoginForm.css";

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = process.env.NODE_ENV === 'production' ? 'https://api.navarraempleo.databot.es/api/auth-token/' : 'http://localhost:3000/api/auth-token/';
      const response = await axios.post(url, {
        username: username,
        password: password,
      });

      // El token de accesso se guarda en el contexto
      localStorage.setItem('accessToken', response.data.token);
      // Redireccion al Search
      window.location.href = '/search';
    } catch (err) {
      // Si hay un error en la autenticación, muestra un mensaje de error
      setError('Credenciales incorrectas. Por favor, intenta de nuevo.');
    }
  };

  return (
    <div className="container">
      <div className="col-sm-6 login">
        <h2>Iniciar Sesión</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username">Correo electrónico</label>
            <input
              type="text"
              className="form-log"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              placeholder='Introduzca su correo electrónico'
            />
          </div>
          <div>
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              className="form-log"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder='Introduzca su contraseña'
            />
          </div>
          <button className="login_cta" type="submit">Iniciar Sesión</button>
        </form>
        <div className='login_forgot'>
          <a href="https://api.navarraempleo.databot.es/accounts/password/reset/" target="_blank">¿Olvidó su contraseña?</a>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
