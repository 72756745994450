import { useState, useEffect } from 'react';

// Función para calcular el rango de páginas
const calculateRange = (results, rowsPerPage) => {
  const range = [];
  const num = Math.ceil(results.length / rowsPerPage);
  for (let i = 1; i <= num; i++) {
    range.push(i);
  }
  return range;
};

// Función para obtener una porción de resultados basada en la página y las filas por página
const sliceResults = (results, page, rowsPerPage) => {
  return results.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};


// Hook personalizado para manejar la lógica de la tabla
const useTable = (results, page, rowsPerPage, orderBy, orderDirection) => {
  // Estados para el rango de páginas y los resultados a mostrar
  const [tableRange, setTableRange] = useState([]);
  const [slice, setSlice] = useState([]);

  // Efecto secundario para manejar cambios en los parámetros y actualizar el rango y los resultados mostrados
  useEffect(() => {
    if (results && results.length > 0) {
      // Clona los resultados para no modificar el array original
      const sortedResults = [...results];

      // Ordena los resultados de acuerdo a los criterios especificados
      sortedResults.sort((a, b) => {
        const isNullFilterA = a.null_filter;
        const isNullFilterB = b.null_filter;

        // Prioriza los elementos con null_filter true al final
        if (isNullFilterA && isNullFilterB) {
          return 0;
        }
        if (isNullFilterA) {
          return 1;
        }
        if (isNullFilterB) {
          return -1;
        }

        // Ordena según el criterio especificado (orderBy y orderDirection)
        const valueA = typeof a[orderBy] === 'number' ? a[orderBy] : JSON.stringify(a[orderBy]);
        const valueB = typeof b[orderBy] === 'number' ? b[orderBy] : JSON.stringify(b[orderBy]);

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return orderDirection === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
          return orderDirection === 'asc' ? valueA - valueB : valueB - valueA;
        } else {
          return 0;
        }
      });

      // Calcula el rango de páginas y actualiza el estado
      const range = calculateRange(sortedResults, rowsPerPage);
      setTableRange([...range]);

      // Obtiene la porción de resultados para la página actual y actualiza el estado
      const slicedData = sliceResults(sortedResults, page, rowsPerPage);
      setSlice([...slicedData]);
    } else {
      // Maneja el caso en que results sea nulo o esté vacío
      setTableRange([]);
      setSlice([]);
    }
  }, [results, page, rowsPerPage, orderBy, orderDirection, setTableRange, setSlice]);

  // Devuelve el estado actualizado de los resultados y el rango
  return { slice, range: tableRange };
};

export default useTable;
