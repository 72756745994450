import React from "react";
import SearchForm from "../SearchForm/SearchForm";
import "./Header.css";

const Header = () => {
  return (
    <div className="holder">
      <header className="header">
        <div className="flex text-center text-white">
          <h2 className="header-title text-capitalize">Búsqueda de ofertas</h2>
          <SearchForm />
        </div>
      </header>
    </div>
  );
};

export default Header;
