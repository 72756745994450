
import { useEffect, useState } from 'react';

const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.navarraempleo.databot.es/api/v1/' : 'http://localhost:3000/api/v1/';
// useSearch is a custom hook to retrieve a search resutls api call
export default function useOptions(url) {
    const [options, setOptions] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); 

    // Se llama al hook useEffect para realizar la llamada a la API
    useEffect(() => {
        async function init() {

            const authToken = "Token " + localStorage.accessToken;
            try {
                const response = await fetch(baseUrl + url, {
                  headers: {
                    Accept: "application/json, text/plain, */*",
                    Authorization: authToken,
                  },
                });
          
                if (!response.ok) {
                  throw new Error("Network error.");
                }
                const data = await response.json();
                const newOptions = data.map((result) => {
                  const label = result.term || (result.city_alt ? result.city + "/" + result.city_alt : result.city) || result.name;
                  return {
                    label: label,
                    value: result.id || result.city || result.name,
                  };
                });
                setOptions(newOptions);
                setLoading(false)

              } catch (err) {
                setError(err);
              } finally {
                setLoading(false)
              }
        }
        init(); // Ejecuta la funcion init()
      },[url]); // Tan solo se usa "useEffect si la variable url se ha modificado"
      return { options, error, loading }
}