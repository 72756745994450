import React, { useState } from 'react';

const TextWithReadMore = ({ text, maxLength }) => {
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);

  const toggleTextVisibility = () => {
    setIsFullTextVisible(!isFullTextVisible);
  };

  return (
    <div>
      {isFullTextVisible ? (
        <p className='text-info'>{text}</p>
      ) : (
        <p className='text-info'>
          {text.length > maxLength
            ? text.substring(0, maxLength) + '...'
            : text}
        </p>
      )}
      {text.length > maxLength && (
        <button className='read-info'  onClick={toggleTextVisibility}>
          {isFullTextVisible ? 'Leer menos' : 'Leer más'}
        </button>
      )}
    </div>
  );
};

export default TextWithReadMore;
