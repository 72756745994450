import React from 'react';
import ExportButton from '../ExportButton/ExportButton'; // Ajusta la ruta según sea necesario
import './StatsHeader.css'; // Importa el archivo CSS

const StatsHeader = () => {
  return (
    <div className="stats-header-container">
      <h2 className="stats-title">Estadísticas</h2>
      <ExportButton />
    </div>
  );
};

export default StatsHeader;
