import React,  { useEffect } from 'react';
import "./PaginationInfo.css";
import {FaAngleRight, FaAngleLeft} from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { useReportContext } from '../../context/reportContext';

const PaginationInfo = (props) => {
  // Carga el contexto del report donde se almacenan los 'ids' de las opciones seleccionadas
  const { report } = useReportContext();

  const { total, rowsPerPage, range, setPage, page, slice } = props;
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  const generateInfoNumShows = () => {
    // Returns a string with the formatted text for the pagination info
    const startRange = slice.length * (page - 1) + 1;
    const endRange = Math.min(slice.length * page, total);
    const totalPages = Math.ceil(total / rowsPerPage);
  
    if (page === 1 && totalPages === 1) {
      return `Mostrados ${total} resultados`;
    } else if (page === 1 && total > 0) {
      return `Mostrados los primeros ${endRange} de ${total} resultados`;
    } else if (page === range.length) {
      return `Mostrados ${total-slice.length} a ${total} de ${total} resultados`;
    } else if (total === 0) {
        return `No se han encontrado resultados`;  
    } else {
      return `Mostrados ${startRange} a ${endRange} de ${total} resultados`;
    }
  };
  return (

    <div className="info_cta_table">
      <div className="info_table">
        <p className="info_num_shows">{generateInfoNumShows()}</p>
        {range.length > 1 && (
          <>
        <div class="cta_info_next">
            <button className={`button ${page > 1 ? 'activeButton' : 'inactiveButton'}`} onClick={() => setPage(page - 1)}>
              <FaAngleLeft/>
            </button>
            <button className={`button ${page < range.length ? 'activeButton' : 'inactiveButton'}`} onClick={() => setPage(page + 1)}>
              <FaAngleRight/>
            </button>
        </div>

          </>
          )
        }
      </div>
      <div className={`cta_table ${report['ids'].length === 0 ? 'not-clickable' : 'clickable'}`}>
        <Link className="button_link" to={`report`}>
          <button className="cta_generar_informe">Generar informe</button>
        </Link>
      </div>
    </div>
  );    
}

export default PaginationInfo