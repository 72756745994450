// El primer parámetro del reducer es el estado actual, en este caso se renombra como report.
export default function reportReducer(report, action) {
    switch (action.type) {
        case "reset": 
            return [];

        case "add": {
            const {param, selectedIds} = action; //parametros 
            let newReport = { ...report, [param]: selectedIds};
            newReport["endpoint"] = buildURL(newReport)
            console.log(newReport)
            return newReport;
        }

        default:
            throw new Error("Unhadled action" + action.type);
    }
}

// Función para construir la URL
function buildURL(params) {
    const baseURL = params["initialEndpoint"]
    const urlParams = [];
  
    for (const key in params) {
      if (Array.isArray(params[key]) && params[key].length > 0) {
        const values = params[key].join(",");
        urlParams.push(`${key}=${values}`);
      }
    }
  
    if (urlParams.length > 0) {
      return `${baseURL}?${urlParams.join("&")}`;
    } else {
      return baseURL;
    }
  }
  