import React from 'react';
import './ResultDetailsItems.css';
import TextWithReadMore from '../TextWithReadMore/TextWithReadMore';

const ResultDetailsItems = (props) => {
  const { label, value } = props;
  const effectiveValue = (value === null || value === undefined || value === "") ? "No definido" : value;

  return (
    <div className={"result-details-item"}>
        <h5 className='label-details'>{label}</h5>
        <TextWithReadMore text={effectiveValue} maxLength={400} />
    </div>
  );
};

export default ResultDetailsItems;