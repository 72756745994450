import React, { createContext, useContext, useEffect, useReducer } from 'react';
import searchReducer from '../reducers/searchReducer';

// Creamos el contexto
const SearchContext = createContext(null);
// Definimos el endpoint de la api sin parámetros
const initialEndpoint = "jobs/"

let initialSearch;
try {
  initialSearch = {initialEndpoint: initialEndpoint, endpoint: initialEndpoint}
  //initialSearch = JSON.parse(localStorage.getItem("search")) ?? {initialEndpoint: initialEndpoint, endpoint: initialEndpoint}

} catch {
  console.error("The search coult not be parsed intro json");
  initialSearch = [];
}


// Proveedor del contexto de la búsqueda
export function SearchProvider(props) {
  const [search, dispatch] = useReducer(searchReducer, initialSearch);
  useEffect(()=> localStorage.setItem("search", JSON.stringify(search)),[search]);
  const contextValue = {
    search,
    dispatch,
  };

  return (
    <SearchContext.Provider value={contextValue}>
      {props.children}
    </SearchContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useSearchContext() {
  const context = useContext(SearchContext)
  if (!context) {
    throw new Error(
      "useSearchContext must be used within a SearchProvider. Wrap a parent component in <SearchProvider> to fix this error."
    )
  }
  return context;
}