import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './SwitchSelect.css'
import { useStatsContext } from '../../../context/statsContext';
import useOptions from '../../../services/useOptions'

const SwitchSelect = (props) => {
  const { firstOption, secondOption } = props;

  // Set initial options
  const firstOptions = useOptions(firstOption.endpoint).options; //TODO ENDPOINT LOGICCOLLECTIONS
  const secondOptions = useOptions(secondOption.endpoint).options; // TODO ENDPOINT LOGICCOLLECTIONS

  const [selectedSwitchOption, setSelectedSwitchOption] = useState('');
  const [selectOptions, setSelectOptions] = useState(null); // Set to null initially
  const [selectPlaceholder, setSelectPlaceholder] = useState(firstOption.placeholder);
  const [selectParam, setSelectParam] = useState(firstOption.param);
  const [selectValue, setSelectValue] = useState("");
  const { dispatch } = useStatsContext();

  const handleSwitchChange = (selectedSwitchValue) => {
    setSelectedSwitchOption(selectedSwitchValue);
    // Reset the selection when the switch changes
    setSelectValue(null);
  };

  const handleSelectChange = (selection) => {
    setSelectValue(selection);
    try {
      if (selection.value) {
        dispatch({ type: 'add', param: selectParam, selectedOptions: [selection.value] });
      }
    } catch (err) {
      console.error('Error en la acción del dispatch:', err);
    }
  };

  const handleReset = () =>{
    dispatch({ type: 'delete', param: selectParam});
    handleSelectChange(null)
  }

  const updateSelectOptions = (selectedSwitchValue) => {
    dispatch({ type: 'delete', param: selectParam });
    switch (selectedSwitchValue) {
      case firstOption.label:
        setSelectOptions(firstOptions || []); // Use empty array if options are not available yet
        setSelectPlaceholder(firstOption.placeholder);
        setSelectParam(firstOption.param);
        break;
      case secondOption.label:
        setSelectOptions(secondOptions || []); // Use empty array if options are not available yet
        setSelectPlaceholder(secondOption.placeholder);
        setSelectParam(secondOption.param);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Simulate a switch change event with the default selected option when the component mounts
    handleSwitchChange(firstOption.label);
    updateSelectOptions(firstOption.label)
  }, [firstOptions,secondOptions]); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    // Check if options are loaded before updating state
      updateSelectOptions(selectedSwitchOption);
  }, [selectedSwitchOption]);

  // Conditional rendering based on the availability of selectOptions
  return (
    <div className="switch-select-container">
      <div className="select-section">
        <div className="placeholder-stats">
          <p>Selecciona: </p>
          {selectValue && (
              <button className='reset' onClick={() => handleReset()}>Limpiar</button>
            )}
        </div>
        <div className="select-container">
          {selectOptions !== null ? (
            <Select
              placeholder={selectPlaceholder}
              closeMenuOnSelect={true}
              options={selectOptions}
              value={selectValue}
              onChange={(selectValue) => handleSelectChange(selectValue)}
            />
          ) : (
            <p className="placeholder-stats">Cargando...</p> // You can customize this loading state
          )}
        </div>
      </div>
      
      <div className="switch-section">
        <p className="placeholder-stats">Generar informe por:</p>
        <div className="switch-container">
          <Switch
            options={[firstOption.label, secondOption.label]}
            selectedOption={selectedSwitchOption}
            onChange={handleSwitchChange}
          />
        </div>
      </div>
    </div>
  );
};


// Switch component
const Switch = ({ options, selectedOption, onChange }) => {
    return (
      <div>
        {options.map((option) => (
          <button className="switch-button"
            key={option}
            onClick={() => onChange(option)}
            style={{
              backgroundColor: selectedOption === option ? 'black' : 'white',
              color: selectedOption === option ? 'white' : 'black',
              padding: '12px',
              cursor: 'pointer',
            }}
          >
            {option}
          </button>
        ))}
      </div>
    );
};

export default SwitchSelect;