import React, { useState } from 'react';
import { MdOutgoingMail } from 'react-icons/md';
import './SendReport.css';
import { useReportContext } from '../../context/reportContext';
import { useSearchContext } from "../../context/searchContext"
import { AiOutlineLoading } from 'react-icons/ai';
import { useUserContext } from '../../context/userContext';

const SendReport = () => {
  const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.navarraempleo.databot.es/api/v1/' : 'http://localhost:3000/api/v1/';
  const { report } = useReportContext();
  const { initialUser } = useUserContext();
  const [state, setState] = useState({ email: '' });
  const [isValidEmail, setIsValidEmail] = useState(false); // Estado para la validación del correo electrónico
  const { search } = useSearchContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const authToken = "Token " + localStorage.accessToken;
    setLoading(true); // Cambia el estado a "loading"
    if (isValidEmail) {
      // El correo electrónico es válido, puedes continuar con el envío del correo
      try {
        await fetch(baseUrl + `send_report/send/?email=${state.email}&ids=${report["ids"]}&user_location=${search["user_location"] ? search["user_location"] : initialUser.default_location.city}`, {
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: authToken,
          },
        });
        setState({ email: '' });
        alert(`Enviado a ${state.email}`);

      } catch (err) {
        console.log(err);
      }

      // Luego, puedes restablecer el valor del estado o realizar cualquier otra acción necesaria
    } else {
      // El correo electrónico no es válido, muestra un mensaje de error o realiza alguna acción apropiada
      alert('Correo electrónico no válido');
    }
    //Cambia el estado de "loading" a false
    setLoading(false);
  };

  const handleChange = (event) => {
    const email = event.target.value;
    setState({ email });

    // Validación del correo electrónico utilizando una expresión regular
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailRegex.test(email);
    setIsValidEmail(isValid);
  };

  return (
    <form className="form_email" onSubmit={handleSubmit}>
      <label className="label_email">
      {loading ? (
        <AiOutlineLoading className="spinner" size={20} />
        ) : (
          <MdOutgoingMail className="icon_search" size={20} />
          )}
        <input
          className={`form-control form-input__input ${isValidEmail ? '' : 'invalid'}`}
          type="email"
          placeholder="Introducir correo electrónico"
          value={state.email}
          onChange={handleChange}
        />
      </label>
      <input className={`send_email ${loading ? 'not-clickable' : 'clickable'}`} type="submit" value={loading ? "Enviando..." : "Enviar"} />
    </form>
  );
};

export default SendReport;
