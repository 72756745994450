import React from 'react';
import './SortableColumnHeader.css';
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
const SortableColumnHeader = ({ column, columnText, currentOrderBy, currentOrderDirection, onColumnClick }) => {
  const isCurrentColumn = currentOrderBy === column;
  const isAsc = currentOrderDirection === 'asc';

  const handleColumnClick = () => {
    onColumnClick(column, isCurrentColumn ? (isAsc ? 'desc' : 'asc') : 'asc');
  };

  return (
    <th onClick={handleColumnClick}>
      {columnText}
      {isCurrentColumn && (
        <span>
          {isAsc ? <GoTriangleDown /> : <GoTriangleUp/>}
        </span>
      )}
    </th>
  );
};

export default SortableColumnHeader;
