import React, { useState, useEffect, useCallback } from 'react';
import { FaSearch } from 'react-icons/fa';
import './TextSearchInput.css';
import { useSearchContext } from '../../context/searchContext';

const TextSearchInput = (props) => {
  const { param, type, placeholder } = props;
  const [inputText, setInputText] = useState('');
  const { dispatch } = useSearchContext();

  const handleSubmit = useCallback(() => {
    try {
      // Verificar si hay texto introducido antes de realizar la acción
      // usar hook de sobreescribir valores
      if (inputText) {
        dispatch({ type: 'add', param: 'search', selectedOptions: [inputText] });
      } else {
        dispatch({ type: 'delete', param: 'search', selectedOptions: [inputText] });
      }
    } catch (err) {
      // Si hay un error en la acción del dispatch, muestra un mensaje de error
      console.error('Error en la acción del dispatch:', err);
    }
  }, [dispatch, inputText]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleSubmit();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputText, param, handleSubmit]);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };



  return (
    <div className="search-form-elem flex flex-sb bg-white form-input">
      <FaSearch className="icon_search" size={20} />
      <input
        className="form-control form-input__input"
        type={type}
        placeholder={placeholder}
        value={inputText}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default TextSearchInput;