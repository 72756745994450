import React, {useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import "./Navbar.css";
import {HiOutlineMenuAlt3} from "react-icons/hi";


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const handleNavbar = () => setToggleMenu(!toggleMenu);

  return (
    <nav className='navbar' id = "navbar">
      <div className='container navbar-content flex'>
        <div className='brand-and-toggler flex flex-sb'>
          <Link to = "/" className='navbar-brand flex'>
            {/* <img src = {logoImg} alt = "site logo" /> */}
            <span className='text-uppercase fw-7 fs-24 ls-1' style={{ flex: 'none' }}>Empleo Navarra</span>
          </Link>
          <button type = "button" className='navbar-toggler-btn' onClick={handleNavbar}>
            <HiOutlineMenuAlt3 size = {35} style = {{
              color: `${toggleMenu ? "#fff" : "#010101"}`
            }} />
          </button>
        </div>

        <div className={toggleMenu ? "navbar-collapse show-navbar-collapse" : "navbar-collapse"}>
          <ul className = "navbar-nav">
            {localStorage.accessToken ? ( // Only render the "Buscar Ofertas" link if token is present
              <li className='nav-item'>
                <NavLink to = "/search" className='nav-link text-uppercase text-white fs-22 fw-6 ls-1'>Buscar ofertas</NavLink>
              </li>
            ) : null}
            {localStorage.accessToken ? ( // Only render the "Estadísticas" link if token is present
              <li className='nav-item'>
                <NavLink to = "/stats" className='nav-link text-uppercase text-white fs-22 fw-6 ls-1'>Estadísticas</NavLink>
              </li>
            ) : null}
            {/* {localStorage.accessToken ? ( // Only render the "Usuario" link if token is present
              <li className='nav-item'>
                <NavLink to = "/user" className='nav-link text-uppercase text-white fs-22 fw-6 ls-1'>Usuario</NavLink>
              </li>
            ) : null} */}
            {localStorage.accessToken ? ( // Only render the "Cerrar Sesión" link if token is present
              <li className='nav-item'>
                <NavLink to="/logout" className='nav-link text-uppercase text-white fs-22 fw-6 ls-1'>Cerrar Sesión</NavLink>
              </li>
            ) : null}
            {!localStorage.accessToken ? ( // Only render the "Iniciar Sesión" link if token is not present
              <li className='nav-item'>
                <NavLink to="/login" className='nav-link text-uppercase text-white fs-22 fw-6 ls-1'>Iniciar Sesión</NavLink>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar