import React from 'react';
import './ProgressBar.css'; // Importa el archivo CSS

const ProgressBar = (props) => {
  const { bgcolor, completed, label, value } = props;

  return (
    <div className='modulo-porcentaje'>
        <div className="col container-styles">
            <div className="filler-styles" style={{ width: `${completed}%`, backgroundColor: bgcolor }}>
              <p className={ completed > 3 ? "value-progressbar" : "value-progressbar-dark" }>{value}</p>
            </div>
        </div>
        <div className='porcentaje'>{label === 'NaN%' ? "0.0%" : label }</div>
    </div>
  );
};

export default ProgressBar;
