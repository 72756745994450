import React from 'react';
import './StatsForm.css';
import DatePicker from '../DatePicker/DatePicker';
import SwitchSelect from '../SwitchSelect/SwitchSelect';
import { useStatsContext } from "../../../context/statsContext"

const StatsForm = () => {
  
  const { query } = useStatsContext();
  const showCurrent = Object.keys(query).includes("selected_from");
  const showComparation = Object.keys(query).includes("previous_from");

  return (
    <div className="stats_form" style={{ display: 'flex' }}>
      <div className="form_dates">
        <div className="date_picker">
          <DatePicker param="selected" placeholder="Período seleccionado: " selected={showCurrent}/>
        </div>
        <div className="date_picker">
          <DatePicker param="previous" placeholder="Período previo: " selected={showComparation}/>
        </div>
        {/* <div className="multiplechoise-stats">
          <StatsMultichoiceSelect
            param="agency" default="" placeholder="Agencia de empleo" is_multi={false} api_options_param="locations_group__agencia__Navarra"/>
        </div> */}
      </div>

      <div className="switch-stats">  
        <SwitchSelect
          firstOption = {{label:"Agencia",param:"agency",placeholder:"Agencia de empleo",endpoint:`lc_options/?lc_type=agency`}}
          secondOption = {{label:"Perfil",param:"profile",placeholder:"Perfil profesional",endpoint:`lc_options/?lc_type=profile`}}/>
      </div>
    </div>
  );
};

export default StatsForm;
