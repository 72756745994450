// El primer parámetro del reducer es el estado actual, en este caso se renombra como search.
export default function searchReducer(search, action) {
    switch (action.type) {
        case "reset": 
            return [];

        case "add": {
            const {param, selectedOptions} = action; //parametros 
            let newSearch = { ...search, [param]: selectedOptions};
            newSearch["endpoint"] = buildURL(newSearch)
            return newSearch;
        }
        
        case "delete": {
          const {param} = action; // parámetro a eliminar
          let { [param]: _, ...newSearch } = search; // elimina el parámetro
          newSearch["endpoint"] = buildURL(newSearch)
          return newSearch;
        }

        default:
            throw new Error("Unhadled action" + action.type);
    }
}

// Función para construir la URL
function buildURL(params) {
    const baseURL = params["initialEndpoint"]
    const urlParams = [];
  
    for (const key in params) {
      if (Array.isArray(params[key]) && params[key].length > 0) {
        const values = params[key].join(",");
        urlParams.push(`${key}=${values}`);
      }
    }
  
    if (urlParams.length > 0) {
      return `${baseURL}?${urlParams.join("&")}`;
    } else {
      return baseURL;
    }
  }
  