import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from "../Loader/Loader";
import "./ResultDetails.css";
import { useNavigate } from 'react-router-dom';
import useSearch from '../../services/useSearch';
import { useSearchContext } from "../../context/searchContext"
import PageNotFound from '../../pages/PageNotFound/PageNotFound';
import ReactModal from 'react-modal';
import DetailAddToReport from '../DetailAddToReport/DetailAddToReport';
import ResultDetailsItems from '../../components/ResultDetailsItems/ResultDetailsItems';
import { FaXmark, } from 'react-icons/fa6';
import CopyLinkButton from '../CopyLinkButton/CopyLinkButton';


const ResultDetails = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const { search} = useSearchContext();

  
  const { results: result, error, loading } = useSearch(
    `jobs/${id}/?user_location=${search["user_location"] ? search["user_location"][0] : null}`
  );
  // Carga el spinner si aún se esta cargando la respuesta de la api
  if(loading) return (<ReactModal isOpen={true} contentLabel="Details"><Loading /></ReactModal>);
  if (!result) return (
    <ReactModal isOpen={true} contentLabel="Details">
      <div className='container'>
        <div className='header_modal'>
          <button type='button' onClick={() => navigate("/search")}>
            <span className='icon_cross'><FaXmark /></span>
          </button>
          <h1 className='title_details'>No hemos encontrado lo que buscas!</h1>
        </div>
    </div>
    </ReactModal>
  )
  if (error) console.log(error)

  return (

    <div>
      <ReactModal isOpen={true} contentLabel="Details" onRequestClose={() => navigate("/search")}>
      <DetailAddToReport id={result?.id} />

      <div className='container'>
        <div className='header_modal'>
          
          <button type='button' onClick={() => navigate("/search")}>
            <span className='icon_cross'><FaXmark /></span>
          </button>

          <h1 className='title_details'>Detalle de la oferta</h1>

        </div>
        
        <div className='result-details-content'>
          <div className='result-details-info'>
              <div className='result-details-item title'>
                <span className='fw-6 fs-24'>{result?.name}</span>
              </div>
          </div>

          <ResultDetailsItems
            label={<span>Publicado el {new Date(result.published_date).toLocaleDateString('es-ES', {day: '2-digit',month: '2-digit',year: 'numeric'})} en <a href={result.url}> {(result.source?.name || 'la solicitud')} </a></span>}
            value={
              <CopyLinkButton link={result.url} />
            }
          />

          <ResultDetailsItems
            label="Empresa" value={result?.company?.name} />
          <ResultDetailsItems
            label="Descripción" value={result["fulltext"]} />
          <div className="details-columns">
            <div className="column-details">
              <ResultDetailsItems
                label="Sector/Actividad Económica" value={result?.professional_category?.term}/>
              <ResultDetailsItems
                label="Modalidad" value={result?.modality?.term}/>
              <ResultDetailsItems
                label="Tipo de contrato" value={result?.contract_type?.term} />
              <ResultDetailsItems
                label="Titulación" value={result?.professional_qualification?.map(item => item.term).join(', ')} />
              <ResultDetailsItems
                label="Permiso de conducción" value={result?.driving_license?.map(item => item.term).join(', ')} />
              <ResultDetailsItems
                label="Idiomas" value={result?.language?.map(item => item.term).join(', ')} />
            </div>
            <div className="column-details">
              <ResultDetailsItems
                label="Localidad" value={result?.city?.city}/>
              <ResultDetailsItems
                label="Jornada" value={result?.work_time?.map(item => item.term).join(', ')} />
              <ResultDetailsItems
                label="Distancia" value={`${result["distance"]} km | ${result["duration"]} min `} />
              <ResultDetailsItems
                label="Experiencia requerida" value={result?.experience?.term} />
              <ResultDetailsItems
                label="Estudios" value={result?.educational_level?.map(item => item.term).join(', ')} />
            </div>
          </div>
        </div>
      </div>

      </ReactModal>
    </div>
  )
}

export default ResultDetails