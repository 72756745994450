import React from 'react';
import "./User.css";
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

// Componente del usuario
const User = () => {
  // Llamamos al hook useUser aquí, dentro del componente funcional

  return (
    <main>
      <Navbar />
      <section className='user'>
        <div className='container'>
          <div className='section-title'>
            <h2>Usuario</h2>
          </div>

          <div className='user-content grid'>
            <div className='user-text'>
              <p className='fs-17-black'>Nombre completo</p>
              <p className='fs-17'>Lorem ipsum dolor</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default User;