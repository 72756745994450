import React from 'react';
import "./Search.css";
import Header from '../../components/Header/Header';
import ResultTable from '../../components/ResultTable/ResultTable';
import ResultDetails from '../../components/ResultDetails/ResultDetails';
import Report from '../../components/Report/Report';
import Navbar from '../../components/Navbar/Navbar';

import { useParams, useResolvedPath } from 'react-router-dom';
import { SearchProvider } from '../../context/searchContext'
import { ReportProvider } from '../../context/reportContext'
import { UserProvider } from '../../context/userContext'
import Footer from '../../components/Footer/Footer';

const Search = () => {
  // Si no tiene token redirige al login
  const { id } = useParams();
  const { pathname } = useResolvedPath() // Obtiene el objeto de coincidencia de ruta
  
  if (localStorage.accessToken == null) {
    window.location.href = '/login';
    return
  }

  return (
  <main>
    <Navbar />
      <div className='search'>
        <UserProvider>
          <SearchProvider>
            <ReportProvider>
              <Header />
              {id ? <ResultDetails /> : null}
              {pathname.includes("report") ? <Report /> : null}
              <ResultTable/>
            </ReportProvider >
          </SearchProvider >
        </UserProvider>
      </div>

    <Footer />
  </main>
  )
}

export default Search
