import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import LoginForm from '../../components/LoginForm/LoginForm';
import Footer from '../../components/Footer/Footer';

const Home = () => {
  return (
    <body>
        <Navbar />
        <LoginForm />
        <Footer />
    </body>
  )
}

export default Home
