import React from 'react';
import LinePlot from '../LinePlot/LinePlot';
import StatsTableGrid from '../StatsTableGrid/StatsTableGrid';
const StatsBody = () => {

  return (
    <div>
        <LinePlot
            title= "Ofertas de empleo"
            height={400}
            width={1400}
            endpoint="test"
        />
        <StatsTableGrid/>
    </div>
    
  );
};

export default StatsBody;
