import React from "react";
import "./ReportDetail.css";
import ReportDetailItem from "../ReportDetailItem/ReportDetailItem";
import TextWithReadMore from "../TextWithReadMore/TextWithReadMore";
import CopyLinkButton from "../CopyLinkButton/CopyLinkButton";

const ReportDetail = (props) => {
  const { item } = props;

  return (
    <div className="report-details-content">
      <div className="report-details-info">
        <div className="report-details-item title">
          <span className="fw-6 fs-20">{item?.name}</span>
        </div>
        <div className={"report-details-item"}>
          <div className="column-descripcion">
            <TextWithReadMore
              text={item.fulltext || "Sin descripción"}
              maxLength={400}
            />
          </div>
          <div className="column-other-details">
            <ReportDetailItem
              label={"Experiencia"}
              value={item.experience?.term}
            />
            <ReportDetailItem label={"Localidad"} value={item.city?.city} />
            <ReportDetailItem
              label={"Formación"}
              value={item.educational_level?.term}
            />

            <ReportDetailItem
              label={"Publicado en " + (item.source?.name || "la solicitud")}
              value={<CopyLinkButton link={item.url} />}
            />
          </div>
        </div>
        <div></div>
      </div>

      <div className="horizontal-divider"></div>
    </div>
  );
};

export default ReportDetail;
