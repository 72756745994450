import React, { createContext, useContext, useEffect, useReducer } from 'react';
import statsReducer from '../reducers/statsReducer';

// Creamos el contexto
const StatsContext = createContext(null);
// Definimos el endpoint de la api sin parámetros
const initialEndpoint = "statistics/get/"

let initialQuery;
try {
  initialQuery = {initialEndpoint: initialEndpoint, endpoint: initialEndpoint}
  //initialQuery = JSON.parse(localStorage.getItem("query")) ?? {initialEndpoint: initialEndpoint, endpoint: initialEndpoint}

} catch {
  console.error("The query coult not be parsed intro json");
  initialQuery = [];
}


// Proveedor del contexto de la búsqueda
export function StatsProvider(props) {
  const [query, dispatch] = useReducer(statsReducer, initialQuery);
  useEffect(()=> localStorage.setItem("statsQuery", JSON.stringify(query)),[query]);
  const contextValue = {
    query,
    dispatch,
  };

  return (
    <StatsContext.Provider value={contextValue}>
      {props.children}
    </StatsContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useStatsContext() {
  const context = useContext(StatsContext)
  if (!context) {
    throw new Error(
      "useStatsContext must be used within a StatsProvider. Wrap a parent component in <StatsProvider> to fix this error."
    )
  }
  return context;
}