import React from 'react';
import "./LogoutForm.css";

function LogoutForm() {
  const handleLogoutForm = async () => {
    try {
      // Realiza una solicitud al endpoint de cierre de sesión en tu API
      // TODO: IMPLEMENTAR ENDPOINT CIERRE DE SESION DE API
      //await axios.post(`${REACT_APP_API_URL}/auth-token/`);

      // Elimina el token de acceso u otra información de autenticación del almacenamiento local o de la sesión
      localStorage.removeItem('accessToken'); // O cualquier otro almacenamiento que estés utilizando

      // Redirige al usuario a la página de inicio de sesión u otra página deseada
      window.location.href = '/'; // Cambia la URL según tus necesidades
    } catch (err) {
      // Si hay un error en el cierre de sesión, maneja el error según tus necesidades
      console.error('Error al cerrar sesión:', err);
    }
  };
      return (
      
      <div className="container">
        <div className="col-sm-6 logout">
          <h2>Cerrar Sesión</h2>
          <button className="primary_button" onClick={handleLogoutForm}>Cerrar Sesión</button>
        </div>
      </div>
    );
  
}

export default LogoutForm;
