import React, { useEffect, useState } from 'react';
import Loading from "../Loader/Loader";
import "./ResultTable.css";
import useSearch from "../../services/useSearch"
import { useSearchContext } from "../../context/searchContext"

import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import PaginationInfo from '../PaginationInfo/PaginationInfo';
import ResultAddToReport from '../ResultAddToReport/ResultAddToReport';
import AllResultsAddToReport from '../AllResultsAddToReport/AllResultsAddToReport';

import SortableColumnHeader from '../SortableColumnHeader/SortableColumnHeader';

import useTable from '../../services/useTable';

const ResultTable = () => {
  // Definimos el estado inicial con resultados vacíos y el spinner de loading activo.
  // Se ejecuta una sola vez (de momento)
  const { search} = useSearchContext();

  const [searchEndpoint, setSearchEndpoint] = useState(search["endpoint"]);
  const {results, error, loading} = useSearch(searchEndpoint)
  
  // Estado para ordenar
  const [orderBy, setOrderBy] = useState('null_filter');
  const [orderDirection, setOrderDirection] = useState('desc'); // Puedes inicializarlo a 'asc' o 'desc' según prefieras.
  
  // PAGINACION
  const [page, setPage] = useState(1); // Pagina inicial
  const [rowsPerPage] = useState(10); // Pagina inicial
  const { slice, range } = useTable(results, page, rowsPerPage, orderBy, orderDirection);


  useEffect(() => {
    // BUG: Si se hace una busqueda mientras loading = true, la petición quedará en cola y sobreescribe a la realizada.
    setSearchEndpoint(search["endpoint"]);

  }, [search]);

  useEffect(() => {
    // BUG: Si se hace una busqueda mientras loading = true, la petición quedará en cola y sobreescribe a la realizada.
  }, [orderBy,orderDirection]);

  const handleColumnClick = (column) => {
    if (orderBy === column) {
      // Si ya estamos ordenando por la misma columna, cambia la dirección.
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Si hacemos clic en una columna diferente, establece la nueva columna y la dirección ascendente.
      setOrderBy(column);
      setOrderDirection('asc');
    }
  };

  if (error) throw (error)
  // Carga el spinner si aún se esta cargando la respuesta de la api
  if(loading) return <Loading />;
  if(!results) return <Loading />;
  return (
    <div>
      <PaginationInfo total={results.length} rowsPerPage={rowsPerPage} range={range} slice={slice} setPage={setPage} page={page} />
      <table>
          <thead>
            <tr class="tr_head">
              <th><AllResultsAddToReport results={results}/></th>
              <SortableColumnHeader column="score" columnText="Puesto ofertado" currentOrderBy={orderBy} currentOrderDirection={orderDirection} onColumnClick={handleColumnClick}/>
              <SortableColumnHeader column="city" columnText="Localidad" currentOrderBy={orderBy} currentOrderDirection={orderDirection} onColumnClick={handleColumnClick}/>
              <SortableColumnHeader column="published_date" columnText="Fecha de publicación" currentOrderBy={orderBy} currentOrderDirection={orderDirection} onColumnClick={handleColumnClick}/>
              <SortableColumnHeader column="experience" columnText="Experiencia" currentOrderBy={orderBy} currentOrderDirection={orderDirection} onColumnClick={handleColumnClick}/>
              <SortableColumnHeader column="source" columnText="Fuente" currentOrderBy={orderBy} currentOrderDirection={orderDirection} onColumnClick={handleColumnClick}/>
              <SortableColumnHeader column="educational_level" columnText="Estudios" currentOrderBy={orderBy} currentOrderDirection={orderDirection} onColumnClick={handleColumnClick}/>
              <SortableColumnHeader column="distance" columnText="Distancia" currentOrderBy={orderBy} currentOrderDirection={orderDirection} onColumnClick={handleColumnClick}/>
              <SortableColumnHeader column="duration" columnText="Duración" currentOrderBy={orderBy} currentOrderDirection={orderDirection} onColumnClick={handleColumnClick}/>
            </tr>
          </thead>
          <tbody class="body_table_oferts">
          {slice.map((result, index) => (
              <tr className={`${result.null_filter ? 'tr_body_secondary' : 'tr_body'}`} key={index}>
              <td><ResultAddToReport id={result.id}/></td>
              <td><Link to={`${result.id}`}>{result.name}</Link></td>
              <td>{result.city?.city || 'Navarra'}</td>
              <td>
                {new Date(result.published_date).toLocaleDateString('es-ES', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </td>
              <td>{result.experience?.term  || 'No definida'}</td>
              <td>{result.source?.name || 'No definida'}</td>
              <td>{result.educational_level[0]?.term || 'No definido'}</td>
              <td>{result.distance !== null ? `${result.distance} km` : "- km"}</td>
              <td>{result.duration !== null ? `${result.duration} min` : "- min"}</td>
            </tr>
          ))}

        </tbody>
      </table>
      <Pagination total={results.length} rowsPerPage={rowsPerPage} range={range} slice={slice} setPage={setPage} page={page} />
    </div>

  );
}

export default ResultTable