import React, { useState, useEffect } from 'react';
import './MultichoiceCheckboxes.css'; // Asegúrate de tener un archivo CSS adecuado
import useOptions from '../../services/useOptions';
import OptionsLoader from "../OptionsLoader/OptionsLoader";
import { useSearchContext } from '../../context/searchContext';

const MultichoiceCheckboxes = (props) => {
  const { param, placeholder } = props;
  const { dispatch } = useSearchContext();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const { options, error, loading } = useOptions(`terms_options/?term_type=${param}`); // Hook que devuelve las opciones

  // Verificar si hay un error al cargar las opciones
  if (error) {throw error;}

  // Lógica para manejar el cambio en las opciones seleccionadas
  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      // Si la opción está seleccionada, la eliminamos
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter((selectedOption) => selectedOption !== option)
      );
    } else {
      // Si la opción no está seleccionada, la agregamos
      setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, option]);
    }
  };

  // Lógica para manejar el cambio en el estado
  const handleStateChange = () => {
    dispatch({type:'add', param, selectedOptions})
  };

  // Llamamos a handleStateChange cada vez que selectedOptions cambia
  useEffect(() => {
    handleStateChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  // Icono de carga
  if(loading) return <OptionsLoader param={param} />;

  return (
    <div className={"search-form-elem bg-white form-input checkbox"}>
      <div className="checkbox-list">
        <h5 className="text-black">{placeholder}</h5>
        {options.map((option, index) => (
          <label key={index} className="checkbox-label">
            <input
              type="checkbox"
              value={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default MultichoiceCheckboxes;
