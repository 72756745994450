import React from 'react';
import "./Stats.css";
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import StatsHeader from '../../components/Stats/StatsHeader/StatsHeader'
import StatsForm from '../../components/Stats/StatsForm/StatsForm'
import StatsBody from '../../components/Stats/StatsBody/StatsBody'
import { StatsProvider } from '../../context/statsContext';

const Stats = () => {
  // Si no tiene token redirige al login
  if (localStorage.accessToken == null) {
    console.log(localStorage.accessToken);
    window.location.href = '/login';
    return
  }
  return (
    <main>
      <Navbar />
      <div>
        <StatsProvider>
          <section className='stats'>
            <div className='container'>
              <StatsHeader />
              <StatsForm />
              <StatsBody />
            </div>
          </section>
        </StatsProvider>
      </div>
      <Footer />
    </main>

  )
}

export default Stats
