import React from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import './StatsTableRow.css'
const StatsTableRow = ({ rowData, progressBarColor, maxValue, maxPrevValue, totalSum, totalPrevSum }) => {
  const label = `${(100 * rowData.count / totalSum).toFixed(1)}%`
  const prev_label = `${(100 * rowData.prev_count / totalPrevSum).toFixed(1)}%`
  const completed = 100 * rowData.count / maxValue
  const prev_completed = 100 * rowData.prev_count / maxPrevValue
  return (
    <tr>
      <div className="label-stats-table">{rowData.label ? rowData.label : "Sin especificar"}</div>
      <div><ProgressBar key={rowData.label} bgcolor={progressBarColor[0]} completed={completed} label={label} value={rowData.count}/></div>
      {rowData.prev_count !== undefined && (<div><ProgressBar key={rowData.label + '-prev'} bgcolor={progressBarColor[1]} completed={prev_completed} label={prev_label} value={rowData.prev_count}/></div>)}
      <div className="diferencias-stats-table">{rowData.abs_diff !== undefined && (
        <div className={rowData.abs_diff < 0 ? 'negative' : rowData.abs_diff > 0 ? 'positive' : 'zero'}>
          {rowData.abs_diff}
        </div>
      )}
      {rowData.rel_diff !== undefined && (
        <div className={rowData.rel_diff < 0 ? 'negative' : rowData.rel_diff > 0 ? 'positive' : 'zero'}>
          {rowData.rel_diff}%
        </div>
      )}

      {rowData.rel_diff !== undefined && (
        <div className={rowData.rel_diff < 0 ? 'negative' : rowData.rel_diff > 0 ? 'positive' : 'zero'}>
          {rowData.rel_diff ? (
            <>
              {rowData.rel_diff > 0 && rowData.rel_diff <= 10 && <span className="arrow-up">↑</span>}
              {rowData.rel_diff > 10 && rowData.rel_diff <= 25 && (
                <>
                  <span className="arrow-up">↑</span>
                  <span className="arrow-up">↑</span>
                </>
              )}
              {rowData.rel_diff > 25 && (
                <>
                  <span className="arrow-up">↑</span>
                  <span className="arrow-up">↑</span>
                  <span className="arrow-up">↑</span>
                </>
              )}
              {rowData.rel_diff < 0 && rowData.rel_diff >= -10 && <span className="arrow-down">↓</span>}
              {rowData.rel_diff < -10 && rowData.rel_diff >= -25 && (
                <>
                  <span className="arrow-down">↓</span>
                  <span className="arrow-down">↓</span>
                </>
              )}
              {rowData.rel_diff < -25 && (
                <>
                  <span className="arrow-down">↓</span>
                  <span className="arrow-down">↓</span>
                  <span className="arrow-down">↓</span>
                </>
              )}
              {rowData.rel_diff === 0 && <span className="dash">—</span>}
            </>
          ) : (
            <span className="dash">—</span>
          )}
        </div>
      )}</div>
    </tr>
  );
};

export default StatsTableRow;
