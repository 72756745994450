import React from 'react';
import './StatsTableLegend.css';
import { useStatsContext } from '../../../context/statsContext';

const StatsTableLegend = ({ data, progressBarColor }) => {
  const { query } = useStatsContext();
  const showDetails = data && data[0] && Object.keys(data[0]).includes("prev_count");

  const selected_period = `${query['selected_from']} a ${query['selected_to']}`
  const previous_period = `${query['previous_from']} a ${query['previous_to']}`

  
  return (
    <thead className='legend-container'>
      {showDetails && (
        <tr>
          <th className='legend-thread'>
            <div className="square" style={{backgroundColor: progressBarColor[0]}}/>
            <p className='bold'>Periodo seleccionado:</p>
            <p>{selected_period}</p>
            </th>
          <th className='legend-thread'>
            <div className="square" style={{backgroundColor: progressBarColor[1]}}/>
            <p className='bold'>Periodo previo:</p>
            <p>{previous_period}</p>
            </th>
        </tr>
      )}
    </thead>
  );
};

export default StatsTableLegend;

