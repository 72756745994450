import React, { useState } from 'react';
import Loading from "../Loader/Loader";
import "./Report.css";
import { useNavigate } from 'react-router-dom';
import useSearch from '../../services/useSearch';
import { useSearchContext } from "../../context/searchContext"
import PageNotFound from '../../pages/PageNotFound/PageNotFound';
import ReactModal from 'react-modal';
import ReportDetail from '../ReportDetail/ReportDetail';
import { FaXmark, } from 'react-icons/fa6';
import { useReportContext } from '../../context/reportContext';
import DownloadReport from '../DownloadReport/DownloadReport';
import SendReport from '../SendReport/SendReport';
import noOptionsimg from "../../images/no_options.jpg";

const Report = () => {
  const navigate = useNavigate();
  // Dropdown desplegable
  const [isSendReportOpen, setSendReportOpen] = useState(false);
  const toggleSendReport = () => {
    setSendReportOpen(!isSendReportOpen);
  };
  const { search} = useSearchContext();
  

  // Carga el contexto del report donde se almacenan los 'ids' de las opciones seleccionadas
  const { report } = useReportContext();
  //const { results, error, loading} = useSearch(`report?ids=${report['ids']}`)

  const { results, error, loading } = useSearch(
    `report/?ids=${report['ids']}&user_location=${search["user_location"] ? search["user_location"][0] : null}`
  );

  // TODO CONVERTIR EN COMPONENTE
  if (report['ids']==="") {
    return (<ReactModal isOpen={true} contentLabel="Details">
              <div className='header_modal informe'>
                <button type='button' className='flex flex-c back-btn' onClick={() => navigate("/search")}>
                  <span className='fs-18 fw-6'><FaXmark /></span>
                </button>
                <h1 class="title_details">Informe ofertas seleccionadas</h1>
              </div>  
              <div class="informe-content noselect">
                <div>No se ha seleccionado ninguna oferta</div>
                <img src = {noOptionsimg} alt = "site logo" />
              </div>
            </ReactModal>)
  }
  // Carga el spinner si aún se esta cargando la respuesta de la api
  if(loading) return (<ReactModal isOpen={true} contentLabel="Details"><Loading /></ReactModal>);
  if (!results) return (<ReactModal isOpen={true} contentLabel="Details"><PageNotFound /></ReactModal>)
  if (error) console.log(error)

  return (
    <div>
      <ReactModal isOpen={true} contentLabel="Details">
      
      <div className='container'>
        <div className='header_modal informe'>
          <button type='button' className='flex flex-c back-btn' onClick={() => navigate("/search")}>
            <span className='fs-18 fw-6'><FaXmark /></span>
          </button>
          <h1 className='title_details'>Informe ofertas seleccionadas</h1>
          <button className="cta_informe" type="button" onClick={toggleSendReport}>
            Enviar / Descargar
          </button>
        </div>

        <div className={`dropdown_report ${isSendReportOpen ? 'open' : 'closed'}`} id="sendReport">
          <div className='sendReport'>
            <SendReport/>
            <DownloadReport/>
          </div>

        </div>

          {results.map((result) => (
            <div key={result.id} className='informe-content'>
              <ReportDetail item={result} />
            </div>
          ))}

      </div>

      </ReactModal>
    </div>
  )
}

export default Report