import React from 'react';
import { useState, useEffect } from 'react';
import Loading from "../../Loader/Loader";
import StatsTable from '../StatsTable/StatsTable'; // Ajusta la ruta según sea necesario
import useSearch from "../../../services/useSearch"
import { useStatsContext } from "../../../context/statsContext"
import './StatsTableGrid.css'

const tablesData = [
  { id:"lc_profile", title: 'Perfil', columns: ["Perfil","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"lc_agency", title: 'Agencia de empleo', columns: ["Agencia","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"professional_category", title: 'Actividad económica', columns: ["Categoría","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  // { id:"sne_ocupation", title: 'Perfil profesional', columns: ["Perfil","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"educational_level", title: 'Nivel educativo', columns: ["Nivel","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  // { id:"professional_qualification", title: 'Cualificación profesional', columns: ["Cualificación","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  // { id:"experience", title: 'Experiencia laboral', columns: ["Experiencia","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  // { id:"work_time", title: 'Tipo de jornada', columns: ["Jornada","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  // { id:"contract_type", title: 'Tipo de contrato', columns: ["Contrato","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  // { id:"salary", title: 'Salario', columns: ["Salario","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"company", title: 'Empresa', columns: ["Empresa","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"city", title: 'Localidad', columns: ["Municipio","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },

  // Agrega más tablas según sea necesario
];

const headers = [
  { colspan: 1, label: "" },
  { colspan: 2, label: "Periodo seleccionado" },
  { colspan: 2, label: "Periodo previo" },
  { colspan: 3, label: "Diferencia" },
];

const maxRows = 7 // Número máximo de filas en la tabla


const StatsTableGrid = () => {
  const { query } = useStatsContext();
  const [statsEndpoint, setStatsEndpoint] = useState(query["endpoint"]);
  const {results, error, loading} = useSearch(statsEndpoint+`&filetype=json&group_by=${tablesData.map(table => table.id).join(',')}`)

  useEffect(() => {
    // BUG: Si se hace una busqueda mientras loading = true, la petición quedará en cola y sobreescribe a la realizada.
    if (query["selected_from"] != undefined & query["selected_to"] != undefined ) {
      setStatsEndpoint(query["endpoint"])
    } 
  }, [query]);

  // Renderizado del componente
  if (query["selected_from"] === undefined & query["selected_to"] === undefined ) return <h3>Selecciona un período</h3>
  if (error) console.log(error)
  // Carga el spinner si aún se esta cargando la respuesta de la api
  if(loading) return <Loading />;
  if (results == null) return <h3>No se han encontrado resultados</h3>
  if (results.error === "No records found") return <h3>No se han encontrado resultados</h3>

    const keys = Object.keys(results);
    keys.forEach(key => {
      const table = tablesData.find(table => table.id === key);
      if (table) {
        table.data = results[key];
      }
    });

    // Comprueba si se esta o no comparando resultados para modificar la clase css de los contenedores de tablas
    const showComparation = Object.keys(query).includes("previous_from");
    return (
      <div className={showComparation ? 'conComparar' : 'sinComparar'}>
        {tablesData.map((tableData, index) => (
          
          <StatsTable 
            key={index} 
            title={tableData.title} 
            columns={tableData.columns} 
            data={tableData.data} 
            maxRows={maxRows} 
            headers={headers}/>
        ))}
      </div>
    );

};



export default StatsTableGrid;
