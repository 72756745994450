import React, { useState, useRef, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './DatePicker.css'; // import your CSS file
import { es } from 'date-fns/locale'
import {
  defaultStaticRanges,
  defaultInputRanges
} from "react-date-range/dist/defaultRanges";
import { useStatsContext } from '../../../context/statsContext';

const DatePicker = (props) => {
  const { param, placeholder, selected } = props;
  const { dispatch } = useStatsContext();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);
  const [selection, setSelection] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  useEffect(() => {
    // Función para cerrar el date-picker-dropdown cuando se hace clic fuera de él
    const handleOutsideClick = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };
    // Agrega el event listener al documento
    document.addEventListener('mousedown', handleOutsideClick);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // Lógica para manejar el cambio en las opciones seleccionadas
  const handleSelect = (ranges) => {
    // Update the state with the new selection
    setSelection(ranges.selection);
    console.log(ranges.selection)
    try
      {
      // Verificar si hay selección antes de realizar la acción
      // usar hook de sobreescribir valores
      if (selection) {
        dispatch({ type: 'add', param: param + "_from", selectedOptions: [ranges.selection.startDate.getFullYear() + "-" + ("0"+(ranges.selection.startDate.getMonth()+1)).slice(-2) + "-" + ("0" + ranges.selection.startDate.getDate()).slice(-2)] });
        dispatch({ type: 'add', param: param + "_to", selectedOptions: [ranges.selection.endDate.getFullYear() + "-" + ("0"+(ranges.selection.endDate.getMonth()+1)).slice(-2) + "-" + ("0" + ranges.selection.endDate.getDate()).slice(-2)] });
      }
    } catch (err) {
          // Si hay un error en la acción del dispatch, muestra un mensaje de error
          console.error('Error en la acción del dispatch:', err);
      }
    };

  const handleReset = () =>{
    dispatch({ type: 'delete', param: param + "_from"});
    dispatch({ type: 'delete', param: param + "_to"});
  }
  const staticRangesLabels = {
    Today: "Hoy",
    Yesterday: "Ayer",
    "This Week": "Esta semana",
    "Last Week": "Semana anterior",
    "This Month": "Este mes",
    "Last Month": "Mes anterior"
  };
  
  const inputRangesLabels = {
    "days up to today": "días hasta hoy",
    "days starting today": "días desde hoy"
  };
  
  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
  }
  
  const esStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );
  const esInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));
  //https://www.npmjs.com/package/react-date-range
  return (
    <div className="date-picker-container">
      {/* Your folded selector trigger */}
      <div className="placeholder-stats">
        <p>{placeholder}</p>
        {selected && (
          <button className='reset' onClick={() => handleReset()}>Limpiar</button>
        )}
      </div>
      
      <div
        className="date-picker-trigger"
        onClick={() => setShowDatePicker(!showDatePicker)}
      >
        <p>{selected ? `${selection.startDate.toLocaleDateString()} - ${selection.endDate.toLocaleDateString()}`: 'Selecciona rango'}</p>
      </div>

      {/* Conditional rendering of the DateRangePicker based on showDatePicker state */}
      {showDatePicker && (
        <div ref={datePickerRef} className="date-picker-dropdown">
          <DateRangePicker
            ranges={[selection]}
            onChange={handleSelect}
            locale={es}
            editableDateInputs={true}
            months={1}
            staticRanges={esStaticRanges}
            inputRanges={esInputRanges}
            dateDisplayFormat = {"d MMM yyyy"}
            scroll={{ enabled: true }}
            direction="vertical"
            maxDate={new Date()}
            moveRangeOnFirstSelection={true}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
