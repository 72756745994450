import React from 'react';
import "./PageNotFound.css";
import Footer from '../../components/Footer/Footer';


const PageNotFound = () => {
  
  return (
    <main>
    <section className='result'>
      <div className='container'>
        <div className='section-title'>
          <h2>Sin resultados</h2>
        </div>

        <div className='result-content grid'>
          <div className='result-text'>
            <p className='fs-17-black'>No hemos encontrado lo que buscas!</p>
            <p className='fs-17'>Error 404 - Not found</p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </main>

  )
}

export default PageNotFound
