
import { useEffect, useState } from 'react';

const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.navarraempleo.databot.es/api/v1/users/me/' : 'http://localhost:3000/api/v1/users/me/';
// useSearch is a custom hook to retrieve a search resutls api call
export default function useUser() {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    // Se llama al hook useEffect para realizar la llamada a la API
    useEffect(() => {
        async function init() {

            const authToken = "Token " + localStorage.accessToken;
            try {
                const response = await fetch(baseUrl, {
                  headers: {
                    Accept: "application/json, text/plain, */*",
                    Authorization: authToken,
                  },
                });
          
                if (!response.ok) {
                  throw new Error("Network error.");
                }
                const user = await response.json();
                setUser(user);

              } catch (err) {
                setError(err);
              }
        }
        init(); // Ejecuta la funcion init()
      },[]); // Tan solo se usa "useEffect si la variable url se ha modificado"
      return { user, error }
}