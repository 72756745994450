import React, { useState, useEffect } from 'react';
import { useReportContext } from '../../context/reportContext';

const AllResultsAddToReport = (props) => {
  const { results } = props;
  // Carga el contexto del report donde se almacenan los 'ids' de las opciones seleccionadas
  const { report, dispatchReport } = useReportContext();
  const [selectedIds, setSelectedIds] = useState(report['ids'])

  // Lógica para manejar el cambio en las opciones seleccionadas
  const handleCheckboxChange = (results) => {
    if (selectedIds.length === results.length) {
      // Si todas las opciones ya estan seleccionadas, se vacía la lista
      setSelectedIds([])
    } else {
      // Si no todas las opciones están seleccionadas, se seleccionan todos los ids de la lista de resultados
      setSelectedIds(results.map(result => result.id));
    }
  };

  // Lógica para manejar el cambio en las opciones seleccionadas
  const handleStateChange = () => {
    // Comprueba si selectedIds es diferente al estado actual
    if (JSON.stringify(selectedIds) !== JSON.stringify(report['ids'])) {
      // Solo realiza el dispatch si los valores son diferentes
      dispatchReport({ type: 'add', param: 'ids', selectedIds });
    }
  };
  
  useEffect(() => {
    // Llamamos a handleStateChange cuando selectedIds cambia
    handleStateChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);
  
  // Actualiza selectedIds cuando report.ids cambie a causa del ResultsDetail
  useEffect(() => {
    setSelectedIds(report.ids || []);
  }, [report.ids]);
  
  return (
      <div className="checkbox-list">
            <input
              type="checkbox"
              checked={selectedIds.length === results.length}
              onChange={() => handleCheckboxChange(results)}
            />
      </div>
  );
};

export default AllResultsAddToReport;
