import React from 'react';
import { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import PlotCounter from '../PlotCounter/PlotCounter';
import './LinePlot.css';
import Loading from "../../Loader/Loader";
import useSearch from "../../../services/useSearch"
import { useStatsContext } from "../../../context/statsContext"

const LinePlot = (props) => {
  const { width, height } = props;


  const { query } = useStatsContext();
  const [statsEndpoint, setStatsEndpoint] = useState(query["endpoint"]);
  const {results, error, loading} = useSearch(statsEndpoint+`&filetype=graph&group_by=work_time`)

  useEffect(() => {
    // BUG: Si se hace una busqueda mientras loading = true, la petición quedará en cola y sobreescribe a la realizada.
    if (query["selected_from"] != undefined & query["selected_to"] != undefined ) {
      setStatsEndpoint(query["endpoint"])
    }
  }, [query]);

  // Renderizado del componente
  if (query["selected_from"] === undefined & query["selected_to"] === undefined ) return <p></p>
  if (error) console.log(error)
  // Carga el spinner si aún se esta cargando la respuesta de la api
  if(loading) return <Loading />;
  if (results == null) return <p></p>
  const { selected_count, previous_count, abs_diff, rel_diff, objects, previous_objects } = results;
  if (objects == null) return <p></p>

  // Extraer arrays "date" y "value" utilizando map
  const dates = objects.map(item => item.date);
  const values = objects.map(item => item.value);
  
  const data = [
    {
      x: dates,
      y: values,
      type: 'scatter',
      mode: 'lines+markers+text',
      marker: { color: 'blue' },
      name: `Periodo seleccionado: ${query['selected_from']} a ${query['selected_to']}`
    },

  ];

  if (previous_objects != null) {
    const previous_dates = previous_objects.map(item => item.date);
    const previous_values = previous_objects.map(item => item.value);
    data.push(
      {
        x: previous_dates,
        y: previous_values,
        type: 'scatter',
        mode: 'lines+markers+text',
        marker: { color: '#fc9e3b' },
        name: `Periodo previo: ${query['previous_from']} a ${query['previous_to']}`
      },);
  }

  const layout = { width: width, height: height, legend:{x: 1, xanchor: 'right', y: 1, font:{size: 15}} };

  return(
    <div className="general-chart">
      <div className="title-counter-general">
        <h3 className="table-title general">Ofertas publicadas</h3>
        <PlotCounter 
          count={selected_count ? selected_count : 0} 
          prev_count={previous_count ? previous_count : 0} 
          abs_diff={abs_diff ? abs_diff : 0}
          rel_diff={rel_diff ? rel_diff : 0}
          />
      </div>
      <Plot data={data} layout={layout} />
    </div>
  ) 
};

export default LinePlot;
