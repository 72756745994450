import React, { useEffect } from 'react';
import {MdFirstPage, MdLastPage, MdChevronLeft, MdChevronRight} from 'react-icons/md'

import './Pagination.css';

const Pagination = (props) => {
  const { total, rowsPerPage, setPage, page, slice } = props;

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  const start = Math.max(1, page - 2);
  const end = Math.min(page + 2, Math.ceil(total / rowsPerPage));
  const visibleRange = Array.from({ length: end - start + 1 }, (_, i) => start + i);

  return (
    <div>
      <div className="pagination">
        <div className="back_buttons_pagination">
                  {page > 1 && (
          <>
            <button
              className={`button ${page === 1 ? 'inactiveButton' : 'activeButton'}`}
              onClick={() => setPage(1)}
            >
              <MdFirstPage/>
            </button>
            <button
              className={`button ${page === 1 ? 'inactiveButton' : 'activeButton'}`}
              onClick={() => setPage(page - 1)}
            >
              <MdChevronLeft/>
            </button>
          </>
        )}
        </div>
        <div className="numbers_pagination">
            {visibleRange.map((el, index) => (
            <button
              key={index}
              className={`button ${page === el ? 'activeButton' : 'inactiveButton'}`}
              onClick={() => setPage(el)}
            >
              {el}
            </button>
          ))}
        </div>
        <div className="next_buttons_pagination">
          {page < Math.ceil(total / rowsPerPage) && (
            <>
              <button
                className={`button ${page === Math.ceil(total / rowsPerPage) ? 'inactiveButton' : 'activeButton'}`}
                onClick={() => setPage(page + 1)}
              >
                <MdChevronRight/>
              </button>
              <button
                className={`button ${page === Math.ceil(total / rowsPerPage) ? 'inactiveButton' : 'activeButton'}`}
                onClick={() => setPage(Math.ceil(total / rowsPerPage))}
              >
                <MdLastPage/>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
