import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import "./MultichoiceSelect.css";
import useOptions from '../../services/useOptions';
import { useSearchContext } from '../../context/searchContext';

const MultichoiceSelect = (props) => {
  const { param, placeholder, api_options_param, is_multi, default: defaultValue } = props;
  const { dispatch } = useSearchContext();
  const { options, loading } = useOptions(`terms_options/?term_type=${api_options_param}`);

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (options && defaultValue) { // Check if options is not null and defaultValue is not empty
      const defaultOptions = is_multi 
        ? options.filter(opt => defaultValue.includes(opt.value))
        : options.find(opt => opt.value === defaultValue);
      setSelectedOptions(defaultOptions || []);
    }
  }, [defaultValue, options, is_multi]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const selectedValues = is_multi 
      ? selectedOptions.map(option => option.value)
      : [selectedOptions.value];
    dispatch({ type: 'add', param, selectedOptions: selectedValues });
  };

  return (
    <div className={"container_multichoise"}>
      <Select
        className={"form-control form-input__input text-black"}
        placeholder={placeholder}
        closeMenuOnSelect={!is_multi}
        isMulti={is_multi}
        options={options}
        onChange={handleSelectChange}
        value={selectedOptions}
        isDisabled={loading}
        isLoading={loading}
      />
    </div>
  );
};

export default MultichoiceSelect;
