import React, { useState } from 'react';
import { useSearchContext } from '../../context/searchContext';
import './DistanceSlider.css'
const DistanceSlider = ({ param, placeholder, distance }) => {
  const [inputValue, setInputValue] = useState(distance); // Inicializa el estado con el valor de 'distance' pasado como prop
  const { dispatch } = useSearchContext();
  let timeoutId;

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    // Si ya hay un timeout programado, cancelarlo
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Programar un nuevo timeout para realizar la acción después de 1,5 segundos
    timeoutId = setTimeout(() => {
      try {
        // Acción con el valor actualizado
        dispatch({ type: "add", param, selectedOptions: newValue ? [newValue] : null });
      } catch (err) {
        console.log(err);
      }
    }, 1500);
  };

  return (
    <div className="distance-filter" >
      <label className="distance-label" htmlFor="distance">Rango de distancia: {inputValue} km</label>
      <input
        style={{width: '300px'}}
        type="range"
        placeholder={placeholder}
        min={0}
        max={500}
        step={10}
        value={inputValue}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default DistanceSlider;