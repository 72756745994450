import { useEffect, useState } from 'react';

const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.navarraempleo.databot.es/api/v1/' : 'http://localhost:3000/api/v1/';

// useSearch is a custom hook to retrieve a search results api call
export default function useSearch(url) {
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        // Inicializa el controlador para cancelar la petición
        const abortController = new AbortController();
        const signal = abortController.signal;

        async function init() {
            setLoading(true);
            const authToken = "Token " + localStorage.accessToken;

            try {
                const response = await fetch(baseUrl + url, {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        Authorization: authToken,
                    },
                    signal,
                });

                const data = await response.json();
                setResults(data);
                setLoading(false);
            } catch (err) {
                // Manejar explícitamente el error de Abort
                if (err.name === 'AbortError') {
                    console.log('La solicitud fue abortada');
                } else {
                    setError(err);
                }
            } finally {
                setLoading(false);
            }
        }

        init();

        return () => abortController.abort();
    }, [url]);
    
    return { results, error, loading };
}