import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './Home.css';

const Home = () => {
  if (localStorage.accessToken != null) {
    console.log(localStorage.accessToken);
    window.location.href = '/search';
    return
  }

  return (
    <main>
        <Navbar />
        <div className='home'>
          <div className="col-sm-6">
            <h2>Navarra Empleo</h2>
            <Link to = "/login" className='primary_button'>Iniciar Sesión</Link>
          </div>
        </div>
        <Footer />
    </main>
  )
}
export default Home
