import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import LogoutForm from '../../components/LogoutForm/LogoutForm';
import Footer from '../../components/Footer/Footer';

const Home = () => {
  return (
    <main>
        <Navbar />
        <LogoutForm />
        <Footer />
    </main>
  )
}

export default Home
