import React, { useState } from 'react';
import './CopyLinkButton.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IoCopy } from 'react-icons/io5';

/**
 * CopyLinkButton Component
 * 
 * Este componente permite a los usuarios copiar un enlace específico al portapapeles.
 * Muestra un botón con un ícono de copia y un texto personalizable, y un mensaje de confirmación
 * personalizable que se muestra temporalmente después de que el enlace se ha copiado.
 *
 * Props:
 * - link (string): El enlace que se desea copiar al portapapeles.
 * - label (string): Texto opcional para el botón. Por defecto es "Copiar enlace".
 * - message (string): Mensaje opcional que se muestra después de copiar. Por defecto es "Copiado!".
 *
 */
const CopyLinkButton = (props) => {
    const {link, label = "Copiar enlace", message = "Copiado!"} = props;
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Resetear el estado después de 2 segundos
    };

    return (
        <div className='btn-copy-link'>
            <CopyToClipboard text={link} onCopy={handleCopy}>
                <button className='btn-copy-link__button'>
                    <IoCopy />
                    {label}
                </button>
            </CopyToClipboard>
            {copied ? <span style={{ marginLeft: '10px' }}>{message}</span> : null}
        </div>
    );
};

export default CopyLinkButton;
