import React, {useEffect, useState} from 'react';
import "./SearchForm.css";
import TextSearchInput from '../TextSearchInput/TextSearchInput';
import MultichoiceSelect from '../MultichoiceSelect/MultichoiceSelect';
import MultichoiceCheckboxes from '../MultichoiceCheckboxes/MultichoiceCheckboxes';
import DistanceSlider from '../DistanceSlider/DistanceSlider';
import { IoFilter } from 'react-icons/io5';
import { useUserContext } from '../../context/userContext';


const SearchForm = () => {

  const [isFiltersOpen, setFiltersOpen] = useState(false);
  const [distance, setDistance] = useState(10); // Default distance of 10 km

  const { initialUser } = useUserContext();
  const [userLocation, setUserLocation] = useState("");

  useEffect(() => {
      if (initialUser && initialUser.default_location) {
          setUserLocation(initialUser.default_location.city);
      } else {
          console.log("El usuario o la ubicación por defecto no están definidos");
          setUserLocation(""); // Reset or set to a default value
      }
  }, [initialUser]); // This effect runs only when initialUser changes

  const toggleFilters = () => {
    setFiltersOpen(!isFiltersOpen);
  };

  const handleDistanceChange = (event) => {
    const newDistance = parseInt(event.target.value, 10);
    setDistance(newDistance);
  };

  return (

    <div className='search-form'>

      <div className='container search filter'>
          <TextSearchInput param="search" type="text" placeholder="Busca nombre o palabra clave"/>
        <button className="cta_filtrar" type="button" onClick={toggleFilters}>
          <span className='icon_filter'><IoFilter /></span>
          {isFiltersOpen ? 'Ocultar' : 'Filtrar'}
        </button>
      </div>
      <div className='container search'>
            {/* <MultichoiceSelect 
              param="agency" default="null" placeholder="Busca por agencias de empleo" is_multi={true} api_options_param="locations_group__agencia__Navarra" /> */}
            
            <MultichoiceSelect 
              param="user_location" default={userLocation} placeholder="Localidad del solicitante" is_multi={false} api_options_param="region__Navarra"/>
            <DistanceSlider 
                param="max_distance" type="range" placeholder="Rango de distancia" distance={distance} onDistanceChange={handleDistanceChange} />
        </div>
      
      <div className={`dropdown_search ${isFiltersOpen ? 'open' : 'closed'}`} id="searchFilters">

        <div className='container search'>
            <MultichoiceCheckboxes 
              param="modality" type="text" placeholder="Modalidad" />
            <MultichoiceCheckboxes 
              param="experience" type="text" placeholder="Experiencia mínima" />
            <MultichoiceCheckboxes 
              param="work_time" type="text" placeholder="Jornada" />
            <MultichoiceCheckboxes 
              param="contract_type" type="text" placeholder="Tipo de contrato" />
            <MultichoiceCheckboxes 
              param="driving_license" type="text" placeholder="Permiso de conducir" />
            <MultichoiceCheckboxes 
              param="language" type="text" placeholder="Idiomas" />
            <MultichoiceCheckboxes 
              param="educational_level" type="text" placeholder="Nivel de estudios" />

              
        </div>
      
      </div>
    </div>
  )
}

export default SearchForm