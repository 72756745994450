import React from 'react';
import { FaRegFileExcel } from "react-icons/fa";
import { AiOutlineLoading } from 'react-icons/ai';
import { useState, useEffect } from 'react';
import './ExportButton.css';
import { useStatsContext } from "../../../context/statsContext"

// TODO BUG CUANDO HAY DATOS VACIOS
const tablesData = [
  { id:"professional_category", title: 'Categoría profesional', columns: ["Categoría","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"sne_ocupation", title: 'Perfil profesional (SNE)', columns: ["Perfil","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"educational_level", title: 'Nivel educativo', columns: ["Nivel","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"professional_qualification", title: 'Cualificación profesional', columns: ["Cualificación","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"experience", title: 'Experiencia laboral', columns: ["Experiencia","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"work_time", title: 'Tipo de jornada', columns: ["Jornada","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"contract_type", title: 'Tipo de contrato', columns: ["Contrato","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"salary", title: 'Salario', columns: ["Salario","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"company", title: 'Empresa', columns: ["Empresa","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"city", title: 'Localidad', columns: ["Municipio","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"lc_profile", title: 'Perfil', columns: ["Perfil","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },
  { id:"lc_agency", title: 'Agencia de empleo', columns: ["Agencia","Nº ofertas","Porcentaje","Ofertas previas","Porcentaje previo","NºOfertas diff","Porcentaje","Tendencia"], data: [{ label: 'Sin resultados', count: 0}] },

  // Agrega más tablas según sea necesario
];


const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.navarraempleo.databot.es/api/v1/' : 'http://localhost:3000/api/v1/';;

const ExportButton = (group_by=null) => {

  const { query } = useStatsContext();
  const [statsEndpoint, setStatsEndpoint] = useState(query["endpoint"]);
  const [loading, setLoading] = useState(false);
  const exportParams = `&filetype=excel&group_by=${tablesData.map(table => table.id).join(',')}`;

  useEffect(() => {
    // BUG: Si se hace una busqueda mientras loading = true, la petición quedará en cola y sobreescribe a la realizada.
    setStatsEndpoint(query["endpoint"]);
  }, [query]);

  // Renderizado del componente
  if (query["selected_from"] === undefined & query["selected_to"] === undefined ) return <p></p>
  // Carga el spinner si aún se esta cargando la respuesta de la api

  const handleExport = async (event) => {
    
    event.preventDefault();
    const authToken = "Token " + localStorage.accessToken;
    try {
      setLoading(true); // Cambia el estado a "loading"
      const response = await fetch(baseUrl+statsEndpoint+exportParams, {
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: authToken,
        },
      });

      if (response.status === 204) {
        // Si la respuesta es 204, mostrar una alerta indicando que no hay resultados
        alert("No hay resultados para este período seleccionado");
        setLoading(false);
        return;
      }

      // Extraer el encabezado Content-Disposition para obtener el nombre del archivo
      const contentDisposition = response.headers.get('Content-Disposition');
      const match = contentDisposition && contentDisposition.match(/filename="(.+)"/);
      var filename = ""
      if (query["previous_from"]!=null) {
        filename = match ? match[1] : `stats__${query["selected_from"]}_${query["selected_to"]}__${query["previous_from"]}_${query["previous_to"]}`;
      } else {
        filename = match ? match[1] : `stats__${query["selected_from"]}_${query["selected_to"]}`;
      }


      // Convertir la respuesta a blob
      const blob = await response.blob();

      // Crear un enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      setLoading(false);

      // Liberar recursos
      window.URL.revokeObjectURL(url);
        
    } catch (err) {
        console.log(err);
    }
  };

  return (

    <div className={`form_download export-button  ${loading ? 'not-clickable' : 'clickable'}`} onClick={handleExport}>
    {loading ? (
      <div>
        <div className="export" type="button">
        <AiOutlineLoading className="loading-icon spinner" size={20} />
          Exportando...
        </div>
      </div>
    ) : (
      <div>
        <div className="export" type="button" >
          <FaRegFileExcel className="icon_download" size={20} />
          Exportar a Excel
        </div>
      </div>
    )}
  </div>
);
};

export default ExportButton;
