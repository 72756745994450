import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Search from './pages/Search/Search';
import ResultDetails from './components/ResultDetails/ResultDetails';
import Report from './components/Report/Report';
import Home from './pages/Home/Home';
import Stats from "./pages/Stats/Stats";
import User from "./pages/User/User";
import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: 'https://6bd378e66e31613ca046bda22e8fb141@o4506558627381248.ingest.sentry.io/4506558649597952',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

if (localStorage.accessToken == null) {
  // Render components for unauthenticated users
  root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/search" element={<Search />} />
        <Route path="/stats" element={<Stats />} />
      </Routes>
    </BrowserRouter>
  );
} else {
  // Render components for authenticated users
  root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />}>
          <Route path=":id" element={<ResultDetails />} />
          <Route path="report" element={<Report />} />
        </Route>
        <Route path="/stats" element={<Stats />} />
        <Route path="/user" element={<User />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

reportWebVitals();