import React, { createContext, useContext, useEffect, useReducer } from 'react';
import reportReducer from '../reducers/reportReducer';

// Creamos el contexto
const ReportContext = createContext(null);
// Definimos el endpoint de la api sin parámetros
const initialEndpoint = "report/"

let initialReport;
try {
  initialReport = {initialEndpoint: initialEndpoint, endpoint: initialEndpoint,  ids: []}
  //initialReport = JSON.parse(localStorage.getItem("report")) ?? {initialEndpoint: initialEndpoint, endpoint: initialEndpoint,  selectedIds: []}
} catch {
  console.error("The report coult not be parsed intro json");
  initialReport = [];
}


// Proveedor del contexto de la búsqueda
export function ReportProvider(props) {
  const [report, dispatchReport] = useReducer(reportReducer, initialReport);
  useEffect(()=> localStorage.setItem("report", JSON.stringify(report)),[report]);
  const contextValue = {
    report,
    dispatchReport,
  };

  return (
    <ReportContext.Provider value={contextValue}>
      {props.children}
    </ReportContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useReportContext() {
  const context = useContext(ReportContext)
  if (!context) {
    throw new Error(
      "useReportContext must be used within a ReportProvider. Wrap a parent component in <ReportProvider> to fix this error."
    )
  }
  return context;
}