import React, { useState } from 'react';
import './StatsTable.css'; // Importa el archivo CSS
import StatsTableRow from '../StatsTableRow/StatsTableRow';
import { FaArrowRight } from "react-icons/fa";
import StatsTableLegend from '../StatsTableLegend/StatsTableLegend';

const StatsTable = ({ title, columns, headers, data, maxRows }) => {
  const progressBarColor = ["#3B99FC","#FC9E3B"]; // Blue & orange

  const maxValue = data.reduce((max, rowData) => (rowData.count > max ? rowData.count : max), 0);
  const maxPrevValue = data.reduce((max, rowData) => (rowData.prev_count > max ? rowData.prev_count : max), 0);
  const totalSum = data.reduce((sum, rowData) => sum + rowData.count, 0);
  const totalPrevSum = data.reduce((sum, rowData) => sum + rowData.prev_count, 0);

  const [showModal, setShowModal] = useState(false);


  const handleVerMasClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="table-container">
      <div className="header-title-button">
        <h3 className="table-title">{title}</h3>

        {data.length > maxRows && (
          <button onClick={handleVerMasClick} className="ver-mas-button">
            Ver más <FaArrowRight />
          </button>
        )}
      </div> 


      <table className="table-style">
        <StatsTableLegend data={data} progressBarColor={progressBarColor}/>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <th className='table-empty' colSpan="Número de columnas en tu tabla">Sin resultados</th>
            </tr>
          ) : (
            data.slice(0, maxRows).map((rowData, index) => (
              <StatsTableRow
                key={index}  // Agrega una clave única para cada fila si es posible
                index={index}
                rowData={rowData}
                progressBarColor={progressBarColor}
                totalSum={totalSum}
                totalPrevSum={totalPrevSum}
                maxValue={maxValue}
                maxPrevValue={maxPrevValue}
              />
            ))
          )}
        </tbody>
      </table>
      
      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="header_modal">
              <span className="cross" onClick={handleCloseModal}>&times;</span>
              <h2 className="title_details">{title}</h2>
              {/* <ExportButton group_by="work_time"/> */}
            </div> 

            <table className="table-style-modal">
            <StatsTableLegend data={data} progressBarColor={progressBarColor}/>
              <tbody>
                {data.map((rowData, index) => (
                  <StatsTableRow index={index} rowData={rowData} progressBarColor={progressBarColor} totalSum={totalSum} totalPrevSum={totalPrevSum} maxValue={maxValue} maxPrevValue={maxPrevValue} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatsTable;