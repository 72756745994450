import React, { useState } from 'react';
import './DownloadReport.css';
import { useReportContext } from '../../context/reportContext';
import { useSearchContext } from "../../context/searchContext"
import { AiOutlineLoading } from 'react-icons/ai';

import { HiOutlineDocumentDownload } from 'react-icons/hi';
const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.navarraempleo.databot.es/api/v1/' : 'http://localhost:3000/api/v1/';;

const DownloadReport = () => {
  const { report } = useReportContext();
  const { search } = useSearchContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const authToken = "Token " + localStorage.accessToken;
    try {
      setLoading(true); // Cambia el estado a "loading"
      const response = await fetch(baseUrl + `download_report/download/?ids=${report["ids"]}&user_location=${search["user_location"] ? search["user_location"][0] : null}`, {
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: authToken,
        },
      });
      // Extraer el encabezado Content-Disposition para obtener el nombre del archivo
      const contentDisposition = response.headers.get('Content-Disposition');
      const match = contentDisposition && contentDisposition.match(/filename="(.+)"/);
      const filename = match ? match[1] : '.pdf';

      // Convertir la respuesta a blob
      const blob = await response.blob();

      // Crear un enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();

      // Liberar recursos
      window.URL.revokeObjectURL(url);

      //Cambia el estado de "loading" a false
      setLoading(false);

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={`form_download  ${loading ? 'not-clickable' : 'clickable'}`} onClick={handleSubmit}>
      {loading ? (
        <div>
          <div className="download" type="button">
          <AiOutlineLoading className="loading-icon spinner" size={20} />
            Descargando...
          </div>
        </div>
      ) : (
        <div>
          <div className="download " type="button" >
            <HiOutlineDocumentDownload className="icon_download" size={20} />
            Descargar PDF
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadReport;
